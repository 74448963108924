const initialState = {
  instance_list: [],
  instanceLoaded: false,
  type_list: [],
  dash_list: [],
  activity_list: [],
  typeSelected: [],
  accountList: [],
  botTxList: [],
  txList: [],
  userInfo: [],
  bandeau: [],
  binance_asset_list: [],
  bandeauLoaded: false,
  isAccountLoaded: false,
  isDataDashLoaded: false,
  newAddedBot: false, //Set true when add new bot to reload dashboard data
  isTypeSelected: false,
  isModified: false,
  typeModified: false,
  isDetailShow: false,
  instanceModified: false, //set true when set to active or inactive state
  binance: null,
  kraken: null,
  coinbase: null,
  coinbasePaymentMethod: null,
  donutData: null,
  donutDataStake: null,
  pool: [],
  notification: [],
  periodSelected: null,
  startDca: null,
  stats: [],
}

export default function (state = initialState, { type, index, value, ...rest }) {
  switch (type) {
    //add all items
    case 'setyexo':
      return {
        ...state,
        ...rest
      }

    case 'setyexoItemTx': {
      const newArray = [...state.botTxList]
      newArray[index] = value
      return {
        ...state,
        botTxList: newArray,
      }
    }

    // instance list
    // add new Item
    // update item
    // change property 

    case 'setyexoAddItem': {
      return {
        ...state,
        instance_list: [...state.instance_list, value]
      }
    }

    case 'setyexoItem': {
      const newArray = [...state.instance_list];
      newArray[index] = value
      return {
        ...state,
        instance_list: newArray,
      }
    }

    case 'setyexoItemReal': {
      const newArray = [...state.instance_list];
      newArray[index].is_real = value.is_real
      return {
        ...state,
        instance_list: newArray,
      }
    }

    case 'setyexoItemActive': {
      const newArraya = [...state.instance_list];
      newArraya[index].is_active = value.is_active
      return {
        ...state,
        instance_list: newArraya,
      }
    }

    case 'setyexoItemPhase': {
      const newArraya = [...state.instance_list];
      newArraya[index].current_phase = value
      return {
        ...state,
        instance_list: newArraya,
      }
    }

    case 'delyexoItem': {
      return {
        ...state,
        instance_list: state.instance_list.filter((liste) => liste.id !== index)
      }
    }
    case 'delyexoType': {
      return {
        ...state,
        type_list: state.type_list.filter((liste) => liste.id !== index)
      }
    }

    // remove account item
    case 'delyexoAccount': {
      return {
        ...state,
        accountList: state.accountList.filter((liste) => liste.id !== index)
      }
    }

    default:
      return state
  }
}

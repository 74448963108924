const initialState = {
    logs: [],
}

export default function (state = initialState, { type, ...rest }) {
    switch (type) {
        case 'SET_CASH_FLOW':
            return {
                ...state,
                ...rest
            }
        default:
            return state
    }
}

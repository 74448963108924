const initialState = {
    chartData: {},

}
// used to stock data for chart
export default function (state = initialState, { type, pair, period, data, exchange, ...rest }) {
    switch (type) {
        //add all items
        case 'SET_RATE_ITEM': {
            const tempData = { ...state.chartData }
            tempData[exchange][period][pair] = data
            return {
                ...state,
                chartData: tempData,
            }
        }
        default:
            return state
    }
}
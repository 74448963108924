const initialState = {
  buy_limit: 45000,
  sell_limit: 46000,
  exchange: 'binance',
  cryptocurrency: 'BTC',
  trade_currency: 'EUR',
  stake: 200,
  buy_limit_spread_rate: 2,
  expected_net_performance: 5,
  compound_rate: 0,
  startdate: new Date(),
  enddate: new Date(),
  period: '86400',
  showRes: '',
  fees: 0,
  trade: 0,
  finalValue: 0,
  finalHold: 0,
  finalValuePercentage: 0,
  performanceAnalysis: 0,
  // tradebot type parameters
  buy_trigger: '',
  sell_trigger: '',
  dataLogs: [],
  buy_target: '',
  is_real: false,
  stop_after_sell: false,
  stop_loss: 0,
  stop_loss_duration: 3,
  net_loss_authorized: false
}

export default function (state = initialState, { type, ...rest }) {
  switch (type) {
    case 'setsmart':
      return { ...state, ...rest }
    default:
      return state
  }
}

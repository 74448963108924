const initialState = {
    currencyDisplay: "EUR"
}

export default function (state = initialState, { type, ...rest }) {
    switch (type) {
        case 'SETTINGSUser':
            return {
                ...state,
                ...rest
            }
        default:
            return state
    }
}

const usermanaged = JSON.parse(localStorage.getItem("userManaged"));

const initialState = usermanaged ? usermanaged :
{
  isManagerPage: false,
  userSelected: '',
  userSelectedName: '',
  userSelectedMail: ''
}

export default function (state = initialState, { type, ...rest }) {
  switch (type) {
    case 'setManager':
      return {
        ...state,
        ...rest
      }
    default:
      return state
  }
}

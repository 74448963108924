const initialState = {
    isDerivatePage: false,
  }
  
  export default function (state = initialState, { type, ...rest }) {
    switch (type) {
      case 'setFuture':
        return {
          ...state,
          ...rest
        }
      default:
        return state
    }
  }
  
const initialState = {
  sidebarShow: 'responsive',
  titre: '',
  soustitre: '',
  nbInstalled: 0,
  nbActive: 0,
  performance: 0,
  createmode: false,
  listeLoaded: false,
  bottype: 'Smartbot',
  botstate: 'Pending buying',
  currencyDisplay: "EUR"
}

export default function (state = initialState, { type, ...rest }) {
  switch (type) {
    case 'set':
      return {
        ...state,
        ...rest
      }
    default:
      return state
  }
}

export const URL_TRADEBOT = "/tradebot/add/";
export const URL_TRADEBOT_DETAILS = "/tradebot/details/";
export const URL_TRADEBOT_LIST = "/tradebot/list/";
export const URL_TRADEBOT_DUPLICATE = "/tradebot/duplicate/";
export const URL_TRADEBOT_POOL = "/tradebot/smartpool/add/";
export const URL_TRADEBOT_SMART = "/tradebot/smartbot/add/";
export const URL_TRADEBOT_PRED = "/tradebot/pred/add/";
export const URL_TRADEBOT_DCA = "/tradebot/dca/add/";
export const URL_TRADEBOT_TYPE = "/tradebot/pattern/type/add/";
export const URL_TRADEBOT_TYPE_INSTANCE = "/tradebot/pattern/instance/add/";
export const URL_TRADEBOT_LOGS = "/tradebot/logs/";
export const URL_MY_STAT = "/my/stat/";
export const URL_FEEDBACK = "/feedback/";
export const URL_SETTINGS = "/settings/";
export const URL_SERVER_CHECK = "/server-check/";
export const URL_TRANSACTIONS = "/transactions/";
export const URL_DASHBOARD = "/dashboard/";
export const URL_START = "/start/";
export const URL_MANAGER = "/manager/";
export const URL_HOME = "/dashboard/";
export const URL_ONBOARDING = "/onboarding/";
export const URL_TOOLS = "/tools/";
export const URL_LOGIN = "/login/";
export const URL_RESET_PASS = "/password/reset/:token";
export const URL_CASHFLOW = "/cashflow/";
export const URL_RATING = "/rating/";
//
// Future
//
export const URL_FUTUR = "/derivates/";
export const URL_FUTURE_SETTINGS = "/derivates/settings/";
export const URL_FUTUR_TRANSACTIONS = "/derivates/transactions/";
export const URL_FUTURE_STATS = "/derivates/statistics/";
export const URL_TRADEBOT_FUTURE = "/derivates/add/tradebot";
//
// Manager urlURL_MANAGER_DASHBOARD
//
// export const URL_MANAGER_DASHBOARD = '/managed/:userId/dashboard/'
export const URL_MANAGER_DASHBOARD = "/managed/dashboard/";
export const URL_MANAGER_SETTINGS = "/managed/settings/";
export const URL_MANAGER_START = "/managed/start/";
export const URL_MANAGER_BACK = "/managed/back/";
export const URL_MANAGER_TRANSACTION_LIST = "/managed/transaction/list/";
export const URL_MANAGER_ADD_TRADEBOT_DCA = "/managed/tradebot/add/dca/";
export const URL_MANAGER_ADD_TRADEBOT = "/managed/tradebot/add/";
export const URL_MANAGER_ADD_TRADEBOT_SMART = "/managed/tradebot/add/smart/";
export const URL_MANAGER_TRADEBOT_DUPLICATE = "/managed/tradebot/duplicate/";
export const URL_MANAGER_TRADEBOT_DETAILS = "/managed/tradebot/details/";
export const URL_MANAGER_TRADEBOT_TYPE_INSTANCE =
  "/managed/tradebot/pattern/instance/add/";
export const URL_MANAGER_TRADEBOT_POOL = "/managed/tradebot/smartpool/add/";
export const URL_MANAGER_TRADEBOT_LIST = "/managed/tradebot/lsit/";
export const URL_MANAGER_STAT = "/managed/stats/";

//
// backend url
//
export const URL_BACK_TRADEBOT = "tradebot/";
export const URL_BACK_TRADEBOT_TYPE = "patternbot/type/";
export const URL_BACK_TRANSACTION_LIST = "transaction/list/";
export const URL_BACK_TRADEBOT_LOGS = "tradebot/logs/list/";
export const URL_BACK_TRADEBOT_LOGS_DOWN = "tradebot/logs/down/";
export const URL_BACK_TRADEBOT_SMART_ADD = "tradebot/smart/add/";
export const URL_BACK_TRADEBOT_PRED_ADD = "tradebot/pred/add/";
export const URL_BACK_TRADEBOT_DCA_ADD = "tradebot/dca/add/";
export const URL_BACK_TRADEBOT_SMART_POOL_ADD = "tradebot/smartpool/add/";
export const URL_BACK_EXCHANGE_INFO = "exchange/info/";
export const URL_BACK_ACTIVITY_TRADEBOT_LIST = "activity/tradebot/list/";
export const URL_BACK_TRADEBOT_ARCHIVE = "archivebot/";
export const URL_BACK_TRADEBOT_INSTANCE_ADD = "tradebot/pattern/instance/add/";
export const URL_BACK_TRADEBOT_FORCE_SELL = "tradebot/force_sell/";
export const URL_BACK_TRADEBOT_FORCE_REDEEM = "tradebot/force_redeem/";
export const URL_BACK_SAVING_DETAILS = "exchange/binance/saving/";

//
// backend managed
//
export const URL_BACK_MANAGER_USERS = "managed/users/";
export const URL_BACK_MANAGER_ACTIVITY_LOGS = "managed/activity/logs/list/";
export const URL_BACK_MANAGER_PAYMENT_METHOD = "managed/paymentmethod/";
export const URL_BACK_MANAGER_BANDEAU = "managed/bandeau/";
export const URL_BACK_MANAGER_ALL_TRADEBOT = "managed/tradebot/list/";
export const URL_BACK_MANAGER_PLATFORM = "managed/platform/";
export const URL_BACK_MANAGER_TRANSACTION = "managed/transaction/list/";
export const URL_BACK_MANAGER_SMARTBOT_POOL = "managed/smartbot/pool/";

// @ts-nocheck
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux'
import './scss/style.scss';// theme
import ThemeProvider from './theme';
import { URL_RESET_PASS } from './helpers/YexoUrls';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./pages/login/Login'));
const ResetPass = React.lazy(() => import('./pages/reset/Reset'));
const Register = React.lazy(() => import('./pages/register/Register'));
const Page404 = React.lazy(() => import('./pages/page404/Page404'));
const Page500 = React.lazy(() => import('./pages/page500/Page500'));
const Activate = React.lazy(() => import('./pages/activation/Activation'));

function App() {

  // @ts-ignore
  const { user: currentUser } = useSelector((state) => state.auth);

  return (
    <BrowserRouter>
      <React.Suspense fallback={loading}>
        <ThemeProvider>
          <Switch>
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
            <Route exact path="/activate/:id/:token" name="Activate Yexo user" render={props => <Activate {...props} />} />
            <Route exact path="/login" name="Login" render={props => <Login {...props} />} />
            <Route exact path="/register/" name="Registration" render={props => <Register {...props} />} />
            <Route path="/password/reset/:token" name="Reset" render={props => <ResetPass {...props} />} />

            {
              currentUser ?
                <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
                :
                <Route path="/" name="Login" render={props => <Login {...props} />} />
            }
          </Switch>
        </ThemeProvider>
      </React.Suspense>
    </BrowserRouter>
  );

}

export default App;

const initialState = {
  instanceListFuture: [],
  instanceLoadedFuture: false,
  strategyListFuture: [],
  dashListFuture: [],
  activityListFuture: [],
  accountListFuture: [],
  botTxListFuture: [],
  txListFuture: [],
  userInfoFuture: [],
  bandeauFuture: [],
  bandeauLoadedFuture: false,
  isAccountLoadedFuture: false,
  isDataDashLoadedFuture: false,
  isDetailShowFuture: false,
  instanceModifiedFuture: false, //set true when set to active or inactive state
  statsFuture: [],
  strat_list: [],
};

export default function (
  state = initialState,
  { type, index, value, ...rest }
) {
  switch (type) {
    //add all items
    case "setyexoFuture":
      return {
        ...state,
        ...rest,
      };

    case "setYexoFutureItemTx": {
      const newArray = [...state.botTxListFuture];
      newArray[index] = value;
      return {
        ...state,
        botTxListFuture: newArray,
      };
    }

    // instance list
    // add new Item
    // update item
    // change property

    case "setYexoFutureAddItem": {
      return {
        ...state,
        instanceListFuture: [...state.instanceListFuture, value],
      };
    }

    case "setYexoFutureItem": {
      const newArray = [...state.instanceListFuture];
      newArray[index] = value;
      return {
        ...state,
        instanceListFuture: newArray,
      };
    }

    case "setYexoFutureItemReal": {
      const newArray = [...state.instanceListFuture];
      newArray[index].is_real = value.is_real;
      return {
        ...state,
        instanceListFuture: newArray,
      };
    }

    case "setYexoFutureItemActive": {
      const newArraya = [...state.instanceListFuture];
      newArraya[index].is_active = value.is_active;
      return {
        ...state,
        instanceListFuture: newArraya,
      };
    }

    // case 'setYexoFutureItemPhase': {
    //   const newArraya = [...state.instanceListFuture];
    //   newArraya[index].current_phase = value
    //   return {
    //     ...state,
    //     instanceListFuture: newArraya,
    //   }
    // }

    case "delyexoItem": {
      return {
        ...state,
        instanceListFuture: state.instanceListFuture.filter(
          (liste) => liste.id !== index
        ),
      };
    }

    // remove account item
    case "delyexoAccount": {
      return {
        ...state,
        accountListFuture: state.accountListFuture.filter(
          (liste) => liste.id !== index
        ),
      };
    }

    default:
      return state;
  }
}

import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import sidebarShow from "./sidebar";
import simulation from "./simulation"
import yexo from "./yexo"
import settings from "./user.settings"
import rates from "./rates"
import cashflow from "./cashFlow"
import manager from "./manager"
import future from "./future";
import yexoFuture from "./yexoFuture";


import sidebarShowUser from "../pages/manager/user/reducers/sidebarUser";
import simulationUser from "../pages/manager/user/reducers/simulationUser"
import yexoUser from "../pages/manager/user/reducers/yexoUser"
import settingsUser from "../pages/manager/user/reducers/user.settingsUser"

export default combineReducers({
  auth,
  cashflow,
  future,
  message,
  manager,
  rates,
  settings,
  settingsUser,
  sidebarShow,
  simulation,
  sidebarShowUser,
  simulationUser,
  yexo,
  yexoUser,
  // future
  yexoFuture
});


export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SESSION_EXPIRES = "SESSION_EXPIRES";
export const SESSION_EXPIRES_PLEASE = "Session expired, please login again";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const ADD_TRADEBOT_SUCCESS = "ADD_TRADEBOT_SUCCESS";
export const ADD_TRADEBOT_FAIL = "ADD_TRADEBOT_FAIL";
export const ADD_TRADEBOT_TYPE_SUCCESS = "ADD_TRADEBOT_TYPE_SUCCESS";
export const ADD_TRADEBOT_INSTANCE_SUCCESS = "ADD_TRADEBOT_INSTANCE_SUCCESS";
export const GET_TRADEBOT_LIST_SUCCESS = "GET_TRADEBOT_LIST_SUCCESS";
export const GET_TRADEBOT_FAIL = "GET_TRADEBOT_FAIL";
export const UPDATE_TRADEBOT_SUCCESS = "UPDATE_TRADEBOT_SUCCESS";
export const UPDATE_TRADEBOT_FAIL = "UPDATE_TRADEBOT_FAIL";

export const ADD_PLATEFORM_SUCCESS = "ADD_PLATEFORM_SUCCESS";
export const ADD_PLATEFORM_FAIL = "ADD_PLATEFORM_FAIL";
export const ADD_PLATFORM_SUCCESSFULLY = "Plateform added successfully";

export const ADD_FEEDBACK_SUCCESS = "ADD_FEEDBACK_SUCCESS";
export const ADD_FEEDBACK_FAIL = "ADD_FEEDBACK_FAIL";

//bandeau
export const SETTING_SAVE_SUCCESS = "SETTING SAVED SUCCESS";
export const SETTING_SAVE_FAIL = "SETTING_SAVE_FAIL";

export const REMOVE_TRADEBOT_POOL_SUCCESS = "REMOVE_TRADEBOT_POOL_SUCCESS";
export const REMOVE_TRADEBOT_POOL_FAIL = "REMOVE_TRADEBOT_POOL_FAIL";
export const FORCE_SELL_FAIL = "FORCE_SELL_FAIL";
export const FORCE_SELL_SUCCESS = "FORCE_SELL_SUCCESS";

const initialState = {
  instance_list: [],
  instanceLoaded: false,
  dash_list: [],
  accountList: [],
  activity_list: [],
  bandeau: [],
  bandeauLoaded: false,
  binance_asset_list: {},
  botTxList: [],
  typeSelected: [],
  txList: [],
  userInfo: [],
  isAccountLoaded: false,
  isDataDashLoaded: false,
  newAddedBot: false, //Set true when add new bot to reload dashboard data
  isTypeSelected: false,
  isModified: false,
  typeModified: false,
  isDetailShow: false,
  instanceModified: false, //set true when set to active or inactive state
  binance: null,
  kraken: null,
  coinbase: null,
  coinbasePaymentMethod: null,
  donutData: null,
  donutDataStake: null,
  pool: [],
  notification: [],
  periodSelected: null,
  startDca: null,
};

export default function (
  state = initialState,
  { type, index, value, ...rest }
) {
  switch (type) {
    //add all items
    case "setyexoUser":
      return {
        ...state,
        ...rest,
      };

    case "setyexoItemTxUser": {
      const newArray = [...state.botTxList];
      newArray[index] = value;
      return {
        ...state,
        botTxList: newArray,
      };
    }

    // instance list
    // add new Item
    // update item
    // change property

    case "setyexoAddItemUser": {
      return {
        ...state,
        instance_list: [...state.instance_list, value],
      };
    }

    case "setyexoItemUser": {
      const newArray = [...state.instance_list];
      newArray[index] = value;
      return {
        ...state,
        instance_list: newArray,
      };
    }

    case "setyexoItemRealUser": {
      const newArray = [...state.instance_list];
      newArray[index].is_real = value.is_real;
      return {
        ...state,
        instance_list: newArray,
      };
    }

    case "setyexoItemActiveUser": {
      const newArraya = [...state.instance_list];
      newArraya[index].is_active = value.is_active;
      return {
        ...state,
        instance_list: newArraya,
      };
    }

    case "setyexoItemPhaseUser": {
      const newArraya = [...state.instance_list];
      newArraya[index].current_phase = value;
      return {
        ...state,
        instance_list: newArraya,
      };
    }

    case "delyexoItemUser": {
      return {
        ...state,
        instance_list: state.instance_list.filter(
          (liste) => liste.id !== index
        ),
      };
    }

    // remove account item
    case "delyexoAccountUser": {
      return {
        ...state,
        accountList: state.accountList.filter((liste) => liste.id !== index),
      };
    }

    default:
      return state;
  }
}
